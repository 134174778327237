import React from "react";
import './Card.css';

const Card = ({img, title, description, link}) => {
  const clickedEvent = () => {
    if(link != null){
      window.open(link, '_blank').focus();
    }
  }

    return (
      <button className="card-button" onClick={clickedEvent}>
        <div className="card">
          <p>{title}</p>
          <p className="card-description">{description}</p>
          <img src={img}></img> 
        </div>  
      </button>
       
    )
}

export default Card;