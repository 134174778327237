/* import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Hans Lim
          <br></br>
          Designer | Developer
        </p>
      </header>
    </div>
  ); */

import './App.css';
import React, { useRef, useState, useEffect  } from 'react';
import { BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import NavBar from './components/NavBar.js';
import Portfolio from './components/Portfolio.js';
import Home from './components/Home.js';
import About from './components/About.js';
import Seperator from './components/Seperator';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => {
  const homeRef = useRef(null);
  const portfolioRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);

  const headerHeight = 50;
  const scrollTo = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth'});
  };

  window.onbeforeunload = function () {
    scrollTo(homeRef);
  }

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    // <Router>
      <div>
        <NavBar home={homeRef} porfolio={portfolioRef} about={aboutRef} contact={contactRef} scrollPosition={scrollPosition}/>
        <div id="home" ref={homeRef}  className='section-start'>
          <Home contact={contactRef}/>
        </div>
        {/* <Seperator delay={5}/> */}
        <div ref={aboutRef}  className='section-start'>
          <About/>
        </div>
        {/* <Seperator/> */}
        <div className='section-start' ref={portfolioRef}>
          <Portfolio/>
        </div>
        <div className='section-start' ref={contactRef}>
          <Contact/>
        </div>
        <div className='section-start'>
          {/* <Footer/> */}
        </div>
      </div>
    // </Router>
  );
};


export default App;
