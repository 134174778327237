import React, { useEffect } from 'react';
import Card from './Card';
import './Portfolio.css'
import MyInspector from '../resources/MI.png'
import TenantAppIcon from '../resources/t-app.png'
import Hans from '../resources/hans.png'
import boqdt from '../resources/boqdt-dark.png'
import controlRoom from '../resources/control-room.png'
import swapsies from '../resources/swapsies-web.png'
import totoro from '../resources/totoro.jpg'
import Seperator from './Seperator';


const Portfolio = () => {
  return (
    <div className='page-content' >
      <div className='center-container centered'>
        <h1 className='header'>Portfolio + Things I've Worked On 📖</h1>
        <div className='card-container'>
          <Card img={TenantAppIcon} title="TenantApp" description={'Xamarin.Forms App'} link={"https://play.google.com/store/apps/details?id=com.ire.tenantapp&hl=en&gl=US"}/>
          <Card img={MyInspector} title="MyInspector" description={'Xamarin.Forms App'} link={"https://play.google.com/store/apps/details?id=com.ire.InspectionApp&hl=en_AU&gl=US"}/>
          <Card img={boqdt}  title="BOQ Defaults App" description={'React.js Web Application'} link={"https://drive.google.com/file/d/1IWHhFOOO5ulFhkknoOvn1Q_SJQtkFHng/view?usp=sharing"}/>
          <Card img={Hans}  title="Personal Website" description={'React.js Web Application'}/>
        </div>
        <div className='card-container'>
          <Card img={controlRoom} title="Control Room" description={'Figma Design'} link={"https://www.figma.com/proto/Cj5XYqQsGH6fibXxLscRqM/Control-Room-Prototype-V3?type=design&node-id=5-0&t=j0WY8f0jOSDmi0Jm-0&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=5%3A0&show-proto-sidebar=1"}/>
          <Card img={swapsies}  title="Swapsies" description={'Figma Design'} link={"https://www.figma.com/proto/32nR2pML55yiLUOBU4ka53/Swapsies-Webpage?node-id=1-535"}/>
          <Card img={totoro}  title="Illustration Work" description={'Graphics and Illustration'} link={'https://hanslim.artstation.com/'}/>
        </div>
        <br/>
        <br/>
      </div>
    </div>
  );
};

export default Portfolio;
