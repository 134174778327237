import './../App.css';
import './NavBar.css'
import React, { useRef , useState } from 'react';
import Button from './Button';
import NavMenu from './NavMenu';


const NavBar = ({home, porfolio, about, contact, scrollPosition}) => {
  const [mobileNavIcon, setMobileNavIcon] = useState("fa-bars")
  const menuRef = useRef();
  const iconRef = useRef();

  const scrollTo = (ref) => {
    //menuRef.current.classList.toggle("show");
    setMobileNavIcon("fa-bars");
    ref.current?.scrollIntoView({ behavior: 'smooth'});
  };

  const handleMenuClick = () => {
    setMobileNavIcon(mobileNavIcon === "fa-xmark" ? "fa-bars" : "fa-xmark");
    menuRef.current.classList.toggle("show");
    //setMobileNavIcon("fa-xmark");
    //iconRef.current.classList.toggle("fa-xmark");
    //iconRef.current.classList.toggle("fa-bars");
  }

  const getBackgroundClass = () => {
    const className = scrollPosition > 100 ? ' has-background' : '';
    return className;
  }

  return (
    <div className={'navbar'+ getBackgroundClass()}>
      <div className='mobile-nav'>
        <div className='hamburger'>
            <button onClick={() => handleMenuClick()}><i ref={iconRef} class={"fa-solid "+mobileNavIcon+" fa-2xl"}></i></button>
        </div>
      </div>
      <div className='NavBar-Container'>
        <nav>
          <div className='logo'>
            <button className='nav-button' onClick={() => scrollTo(home)}><h2 className='logo-button orange-text'>Hans Lim.</h2></button>
          </div>
          <div className='nav-buttons'>
              <button className='nav-button' onClick={() => scrollTo(about)}>About</button>
              <button className='nav-button' onClick={() => scrollTo(porfolio)}>Portfolio</button>
              <button className='contact-button-small' onClick={() => scrollTo(contact)}>Contact</button>
          </div>
        </nav>
      </div>
      {/* <Menu/> */}
      <NavMenu home={home} porfolio={porfolio} about={about} contact={contact} menuRef={menuRef} setMobileNavIcon={setMobileNavIcon}/>
    </div>
  )
};

export default NavBar;
