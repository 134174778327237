import React, { useEffect } from 'react';
import './About.css'
import LinedPaper from './LinedPaper';
import headerImg from '../resources/halftone-image.png'

const About = () => {
  /* useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 */
  return (
    <div className='about-section'>
      {/* <div className='halftone-upper'>
        <img src={headerImg} style={{backgroundRepeat:'repeat-x', width:'100%', aspectRatio:1}}/>
      </div> */}
      <div className='tan-background'>
        <div className='center-container'>
          <LinedPaper>
            <div className='body-box'>
              <h1 className='h2 black-text animate' style={{marginBottom:40,'--delay': 7}}>About Me 💁‍♂️</h1>
              <p className='content animate' style={{'--delay': 7}}> 
              
                Meet the passionate Software Developer behind the screen! 🚀 
                <br/>
                <br/>
                With a knack for both Web and Mobile technologies, I dive into the realms of React.js and Xamarin.Forms to craft seamless digital experiences. 
                Currently, I'm weaving magic as a Mobile Applications Developer, honing my expertise in Xamarin.Forms/.NET MAUI and ASP.NET.
                <br/>
                <br/>
                Beyond coding, I transform into a design virtuoso, crafting captivating UI/UX, Graphic Design, and Illustrations. Venture into my portfolio to witness a symphony of visually stunning projects, 
                where clean code meets responsive designs, delivering exceptional user experiences. Let's build something extraordinary together! 💻✨
              </p>
            </div>
          </LinedPaper>
        </div>
      </div>
      {/* <div className='transparent-lower'/> */}
    </div>
  );
};


              {/* I'm a passionate <span style={{fontWeight:'bold'}}>Software Developer</span> working with both Web and Mobile technologies, such as <span style={{fontWeight:'bold'}}>React.js & Xamarin.Forms</span>. 
              Currently, I'm working as a <span style={{fontWeight:'bold'}}>Mobile Applications Developer</span>, specializing in <span style={{fontWeight:'bold'}}>Xamarin.Forms/.NET MAUI and ASP.NET</span>.
              When I'm not developing, you can catch me designing anything, from <span style={{fontWeight:'bold'}}>UI/UX, Graphic Design or Illustration! </span>
              <br/><br/>
              Explore my portfolio for visually appealing projects that showcase clean code, responsive designs, and exceptional user experiences.  */}
           

        {/* <div className='paper'>
          <div className='left-margin-line'/>
          <div className='body-box'>
            <h1 className='h2 black-text animate' style={{marginBottom:40,'--delay': 7}}>About Me 💁‍♂️</h1>
            <p className='contentanimate' style={{'--delay': 7}}> 
            
              Meet the passionate Software Developer behind the screen! 🚀 
              <br/>
              <br/>
              With a knack for both Web and Mobile technologies, I dive into the realms of React.js and Xamarin.Forms to craft seamless digital experiences. 
              Currently, I'm weaving magic as a Mobile Applications Developer, honing my expertise in Xamarin.Forms/.NET MAUI and ASP.NET.
              <br/>
              <br/>
              Beyond coding, I transform into a design virtuoso, crafting captivating UI/UX, Graphic Design, and Illustrations. Venture into my portfolio to witness a symphony of visually stunning projects, 
              where clean code meets responsive designs, delivering exceptional user experiences. Let's build something extraordinary together! 💻✨
            </p>
          </div>
        </div> */}
export default About;
