import './../App.css';
import './NavBar.css'
import React, { useRef , useState } from 'react';


const NavMenu = ({home, porfolio, about, contact, menuRef, setMobileNavIcon}) => {

    const scrollTo = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'instant'});
    };

    const handleClick = (ref) => {
        setMobileNavIcon("fa-bars");
        menuRef.current.classList.toggle("show");
        scrollTo(ref);
    }

    return (
    <div ref={menuRef} className='nav-menu'>
        <div className='menu-buttons'>
            <button className='menu-button' onClick={() => handleClick(home)}>Home</button>
            <button className='menu-button' onClick={() => handleClick(about)}>About</button>
            <button className='menu-button' onClick={() => handleClick(porfolio)}>Portfolio</button>
            <button className='menu-button' onClick={() => handleClick(contact)}>Contact</button>
        </div>
    </div>
    )
};

export default NavMenu;