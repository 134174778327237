import React, { useEffect } from 'react';
import './Home.css'
import '../animations.css'
import img from '../resources/hans-no-shadow.png'
import footerImg from '../resources/footer-graphic.png'
import headerImg from '../resources/header-graphic.png'
import shadow from '../resources/shadow.png'
const Home = ({contact}) => {
  const scrollTo = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth'});
  };

  return (
     <div className='home-section'>
        <div className='center-container'>
          <div className='landing-div'>
            <div className='left-margin-line'/>
            <div className='name-description' style={{marginTop:10, marginBottom:10}}>
              <h2 className='heading2 animate orange-text' style={{'--delay': 1}}>Hi there! <span className="wave">👋</span></h2>
              <h2 className='heading1 animate black-text' style={{'--delay': 4}}> I'm Hans.</h2>
              <p className='subheading animate black-text' style={{'--delay': 4}}>I'm a creative software developer and designer, <br/>based in Sydney, Australia.</p>
              <p className='content body animate' style={{marginTop:10,'--delay': 4}}>I'm passionate about building usable, friendly and user-centric experiences.</p>
              <button className='contact-button large-button animate' style={{marginTop:30, '--delay': 4}} onClick={() => scrollTo(contact)}>Contact Me</button>
            </div>
            <div className='image-block animate' style={{'--delay': 6}}>
              <div className='circle background-gradient'/>
              <div className='character-group'>
                <img className='splash-image vert-move' src={img}></img>
                <div className='shadow-container'>
                  <img src={shadow} className='shadow-image size-change'/>
                </div>
              </div>
            </div>
        </div>
        <div className='background-images'>
          <div className='header-image-block'>
            <img src={headerImg} className='header-graphic'/>
          </div>
          <div className='footer-image-block'>
            <img src={footerImg} className='footer-graphic'/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;