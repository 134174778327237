import './LinedPaper.css'
const LinedPaper = ({children}) => {
    return (
        <div className='box'>
          <div className='left-margin-line' />
          <div className='contact-box'>
             {children}
          </div>
        </div>
    )
}
export default LinedPaper;