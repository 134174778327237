import './Contact.css';
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import LinedPaper from './LinedPaper';

const Contact = () => {
  const form = useRef();
  const name = useRef();
  const email = useRef();
  const message = useRef();

  const sendEmail = (e) => {
    
    emailjs.sendForm('service_cuzhges', 'template_aa0lea1', form.current, '-iWV1T5a8ySvxdlXa')
      .then((result) => {
          console.log(result.text);
          console.log("message sent successfully");
          alert("Message sent successfully!");
          name.value = "";
          email.value = "";
          message.value = "";
      }, (error) => {
          console.log(error.text);
          alert("Message could not be sent. Please try again later.");
      });

    e.preventDefault();
  };

  const openLink = ()=>{
    window.open('https://www.linkedin.com/in/hans-lim-732772143', '_blank').focus();
  } 
  
  return (
  <div className='contact-section'>
      <div className='center-container'>
        <LinedPaper>
          <h1 className='header'>Interested? Keep in touch! <span>📧</span></h1>
          <p className="contact-content body">Contact me via 
            <span><button className="linkedin-button" style={{fontWeight:"bold"}} onClick={openLink}> LinkedIn 
              <span>
                  <i class="fa-brands fa-linkedin fa-xl" style={{color: 'var(--matcha-green)', margin:5}}></i> 
              </span>
            </button></span> 
            or through the form below
          </p>
          
          <form ref={form} onSubmit={sendEmail}>
            <input ref={name} placeholder="Name..." name="from_name"/>
            <input ref={email} placeholder="Email..." name="user_email"/>
            <textarea ref={message} placeholder="Message..." name="message"/>
            <input className="submit-button" type="submit" value="Send Message" ></input>
          </form>
        </LinedPaper>
        {/* <div className='box'>
          <div className='left-margin-line' />
          <div className='contact-box'>
            <h1 className='header'>Interested? Keep in touch! <span>📧</span></h1>
            <p className="contact-content body">Contact me via 
              <span><button className="linkedin-button" style={{fontWeight:"bold"}} onClick={openLink}> LinkedIn 
                <span>
                    <i class="fa-brands fa-linkedin fa-xl" style={{color: 'var(--matcha-green)', margin:5}}></i> 
                </span>
              </button></span> 
              or through the form below
            </p>
            
            <form ref={form} onSubmit={sendEmail}>
              <input ref={name} placeholder="Name..." name="from_name"/>
              <input ref={email} placeholder="Email..." name="user_email"/>
              <textarea ref={message} placeholder="Message..." name="message"/>
              <input className="submit-button" type="submit" value="Send Message" ></input>
            </form>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Contact;